<template>
  <div class="d-flex" v-if="users.length">
    <user-avatar
      class="user-group"
      elevated
      v-for="user in users.length > 5 ? 5 : users.length"
      :user="users[user - 1]"
      :key="user - 1 + 'users'"
      icon
      :style="`z-index: ${users.length - user};left:${(-user - 1) * 14}px`"
    ></user-avatar>
    <v-sheet
      v-if="users.length > 5"
      class="user-group d-flex flex-column white--text align-center justify-center rounded-circle fs-11px align-right font-weight-medium"
      :style="`z-index: 0; left: ${-7 * 14 + 4}px; top: 3px`"
      width="28"
      height="28"
      color="grey darken-4"
      ><span class="font-weight-regular" style="line-height: 7px">+</span
      ><span style="line-height: 13px">{{ users.length - 5 }}</span></v-sheet
    >
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
    },
  },
};
</script>

<style>
.user-group {
  position: relative;
}
</style>
