<template>
  <div
    style="display: relative"
    class="alt-comp-wrapper"
    :class="{ 'details-hidden': hideDetails, 'hide-border': hideBorder }"
  >
    <v-autocomplete-alt
      auto-select-first
      :rules="required ? [allRules.required] : []"
      :label="hideLabel ? null : $attrs.label || 'Access Group'"
      id="accessGroup"
      placeholder="Select an Access Group"
      :value="value"
      @input="emitAccessGroup"
      :items="accessGroups"
      dense
      :filter="accessGroupsFilter"
      filled
      :hide-details="hideDetails"
      item-text="firstName"
      item-value="id"
      :loading="isAccessGroupsLoading"
      :readonly="readonly"
      clearable
      v-bind="$attrs"
    >
      <template v-for="(item, key) in ['selection', 'item']" #[`${item}`]="data">
        <div class="table-row" :key="key + item + data.index">
          <div
            class="table-cell name user-avatar user-fullname"
            :key="key + item + data.index + 'user-Name'"
          >
            {{ data.item.name }}
          </div>
          <div class="table-cell users" :key="key + item + data.index + 'users'">
            <users-avatars :users="data.item.users"></users-avatars>
          </div>
        </div>
      </template>
      <template #append-item>
        <div v-intersect="loadMore" />
      </template>
    </v-autocomplete-alt>
  </div>
</template>

<script>
import UsersAvatars from "../../Shared/components/UsersAvatars.vue";
export default {
  props: {
    value: {
      type: Object,
    },
    obj: {
      type: Object,
    },
    accessGroups: {
      type: Array,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowView: {
      type: Boolean,
      default: true,
    },
    isAccessGroupsLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      total: 0,
      options: {
        search: null,
      },
    };
  },
  methods: {
    emitAccessGroup(id) {
      if (id) {
        var g = this.accessGroups.find((elm) => elm.id == id);
        this.$emit("input", g);
        this.$emit("update:obj", g);
      } else {
        this.$emit("input", null);
        this.$emit("update:obj", null);
      }
    },
    accessGroupsFilter(item, queryText, itemText) {
      const text1 = item.firstName.toLowerCase();
      const text2 = item.lastName.toLowerCase();
      const text3 = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1
      );
    },
    loadMore(undefine, _, isIntersecting) {
      if (isIntersecting && this.total > this.accessGroups.length && !this.isAccessGroupsLoading) {
        // this line to loadMore if there is no search
        if (!this.options.search) this.options.page++;
        // this line to clear search only if there is item selected
        if (this.value) this.options.search = "";
      }
    },
  },
  created() {},
  watch: {
    options: {
      handler() {},
      deep: true,
    },
    obj() {
      this.refreshAutocomplete();
    },
  },
  components: { UsersAvatars },
};
</script>
<style lang="scss">
.table-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.table-cell {
  margin-right: 30px;
  padding: 5px;
}

.name {
  text-align: left;
}

.users {
  flex: 3;
  text-align: center;
}
.user-fullname {
  font-weight: 600;
  font-size: 14px;
  color: rgba(42, 54, 59, 0.87);
  line-height: 1;
}
</style>
