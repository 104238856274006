<template>
  <div>
    <v-container class="px-6 pt-2" style="background: #eceff1">
      <v-row class="mt-0 mb-2" justify="center" align="center">
        <v-col>
          <div class="d-flex align-center" v-if="client">
            <v-img
              v-if="client.logoUrl != null && client.logoUrl != ''"
              :src="client.logoUrl"
              max-height="40"
              max-width="40"
              position="left center"
              contain
            >
            </v-img>
            <v-img
              v-else
              src="/img/DNA_Symbol.png"
              max-height="40"
              max-width="40"
              position="left center"
              contain
            >
            </v-img>
            <span class="ml-2">{{ client.name }}</span>
          </div>
        </v-col>
        <v-col lg="4" md="6" sm="12" class="d-flex flex-row" style="gap: 0.5rem">
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field d-inline-flex"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            hide-details=""
            clearable
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page="15"
        :loading="loadingStates.table"
        class="elevation-2 connected-projects-table"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'far fa-arrow-to-left',
          lastIcon: 'far fa-arrow-to-right',
          prevIcon: 'far fa-angle-left',
          nextIcon: 'far fa-angle-right',
          itemsPerPageOptions: [15, 30, 50, 100],
        }"
      >
        <template v-slot:progress>
          <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
        </template>

        <template v-slot:[`header.jiraCode`]="{ header }">
          <i :class="header.icon"></i>
          {{ header.text.toUpperCase() }}
        </template>

        <template v-slot:[`item.jiraCode`]="{ item }">
          <a
            v-if="item.jiraCode != null && item.jiraCode != ''"
            target="_blank"
            class="jira-external-link"
            @click.stop
            :href="'https://dangeloconsultants.atlassian.net/browse/' + item.jiraCode"
          >
            <span class="icon-wrpr mr-1">
              <i class="fab fa-jira org-icon"></i>
              <i class="far fa-external-link alt-icon"></i>
            </span>
            <span>{{ item.jiraCode }}</span>
          </a>
          <span v-else class="text--disabled">
            <i class="fad fa-unlink"></i>
          </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <project-status :status="item.status" small></project-status>
        </template>

        <template v-slot:[`item.stage`]="{ item }">
          <project-stage :stage="item.stage" small></project-stage>
        </template>

        <template v-slot:[`item.fullName`]="{ item }">
          <h4>{{ item.fullName }}</h4>
        </template>

        <template v-slot:[`item.createDate`]="{ item }">
          <dater :date="item.createDate" date-only></dater>
        </template>

        <template v-slot:[`item.updateDate`]="{ item }">
          <dater :date="item.updateDate" date-only></dater>
        </template>

        <template v-slot:[`item.spaceCount`]="{ item }">
          <space-count :count="item.spaceCount" />
        </template>

        <template v-slot:[`item.equipmentCount`]="{ item }">
          <equipment-count :count="item.equipmentCount" />
        </template>

        <template v-slot:[`item.totalMsrpPrice`]="{ item }">
          <span>{{ item.totalMsrpPrice | usdFormat }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            elevation="0"
            color="info"
            target="_blank"
            @click.stop
            :href="'/projects/' + item.id"
          >
            <i class="fal fa-external-link"></i>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <img width="500" src="/img/art/fogg-no-connection-2.png" />
          <p class="font-weight-bold">No Data Available!</p>
        </template>

        <template v-slot:loading>
          <video width="250" muted loop autoplay style="padding: 0.5rem">
            <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
          </video>
          <p class="font-weight-bold">Searching the Cosmos...</p>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import ProjectStatus from "../../../Projects/components/ProjectStatus.vue";
import ProjectStage from "../../../Projects/components/ProjectStage.vue";
import ApiService from "../../../Projects/services/projects-service";
import clientProjectHeader from "../config/tables/clientProject.header";

export default {
  name: "client-projects",
  props: {
    client: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        clientIds: null,
      },
      loadingStates: {
        table: false,
      },
      headers: clientProjectHeader,
      entities: [],
      total: 0,
      projectsTimerId: null,
    };
  },

  methods: {
    getDataDebounced() {
      if (this.projectsTimerId == null) {
        this.projectsTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectsTimerId);

      // delay new call 400ms
      this.projectsTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    getData() {
      this.loadingStates.table = true;
      ApiService.query(this.$clean(this.options))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
  },
  watch: {
    options: {
      handler(val) {
        if (val.clientIds) this.getDataDebounced();
      },
      deep: true,
    },
    client: {
      handler(val) {
        if (val) {
          this.options.clientIds = [val.id];
        } else {
          this.entities = [];
          this.options.clientIds = null;
        }
      },
      deep: true,
    },
  },
  components: { ProjectStatus, ProjectStage },
};
</script>

<style lang="scss">
.projects-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.jira-external-link {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s ease-out;

  span {
    color: rgba($info-base, 1);
  }

  .icon-wrpr {
    width: 12px;
    height: 12px;
  }

  .org-icon {
    position: absolute;
    opacity: 1;
    transform: scale(1) rotate(0deg);
    transition: all 0.2s ease-out;
  }

  .alt-icon {
    position: absolute;
    opacity: 0;
    transform: scale(0) rotate(-180deg);
    transition: all 0.2s ease-out;
  }

  &:focus,
  &:hover {
    background: rgba($info-base, 1);
    span {
      color: #fff;
    }

    .org-icon {
      opacity: 1;
      transform: scale(0) rotate(180deg);
    }

    .alt-icon {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }
}
</style>
