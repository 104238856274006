<template>
  <div class="alt-comp-wrapper" :class="{ 'details-hidden': hideDetails }">
    <v-autocomplete-alt
      auto-select-first
      :rules="required ? [allRules.required] : []"
      label="Client"
      id="projectClient"
      placeholder="Client"
      :value="value"
      @input="emitClient"
      :items="clients"
      :search-input.sync="options.search"
      dense
      filled
      no-filter
      :hide-details="hideDetails"
      item-text="name"
      item-value="id"
      :loading="isClientsLoading"
      :readonly="readonly"
      clearable
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <v-row
          :key="item + '_clientSelector'"
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none"
        >
          <v-col sm="auto" class="d-flex align-center mr-1">
            <v-img
              v-if="data.item.logoUrl != null && data.item.logoUrl != ''"
              :src="data.item.logoUrl"
              height="28"
              width="48"
              position="center center"
              contain
            >
            </v-img>
            <v-img
              v-else
              src="/img/DNA_Symbol.png"
              height="28"
              width="48"
              position="center center"
              contain
            >
            </v-img>
          </v-col>
          <v-col class="d-flex align-center col-auto">
            <div class="d-flex align-center font-weight-medium" style="font-size: 14px">
              {{ data.item.name }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template #append-item>
        <div v-intersect="loadMore" />
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      :loading="isClientsLoading"
      :disabled="isClientsLoading || readonly"
      @click="getData"
    >
      <i class="fas fa-sync"></i>
    </v-btn>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      @click="viewClient(value)"
      :disabled="value == null"
      v-if="allowView"
    >
      <i class="fad fa-eye fs-14px"></i>
    </v-btn>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      class="pa-0"
      small
      @click="addNewClient()"
      :disabled="isClientsLoading || readonly"
      v-if="allowAdd"
    >
      <i class="fal fa-plus fs-14px"></i>
    </v-btn>
    <edit-client
      v-if="allowAdd || allowView"
      @save="onClientAdded"
      ref="addClient"
      :nested="nested"
    ></edit-client>
  </div>
</template>

<script>
import clientsAPI from "../../Companies/Clients/services/clients-service";
import EditClient from "../../Companies/Clients/components/EditClient.vue";
export default {
  props: {
    value: {
      type: [Number, String],
    },
    obj: {
      type: Object,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowAdd: {
      type: Boolean,
      default: true,
    },
    allowView: {
      type: Boolean,
      default: true,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clients: [],
      isClientsLoading: false,
      clientsTimerId: null,
      total: 0,
      mainSearchInFocus: false,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
    };
  },
  methods: {
    emitClient(id) {
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.clients.find((elm) => elm.id == id) : null);
    },
    getData() {
      this.isClientsLoading = true;
      clientsAPI
        .queryLite({
          ...this.options,
          itemsPerPage: this.options.search && this.total ? this.total : this.options.itemsPerPage,
          search: this.options.search || "",
        })
        .then((resp) => {
          const isSearch = !!this.options.search;
          this.addUniqueArr(this.clients, resp.data.items, "id", isSearch);
          if (!isSearch) this.total = resp.data.total;
          this.refreshAutocomplete();
          this.isClientsLoading = false;
        })
        .catch(() => {
          this.isClientsLoading = false;
        });
    },
    clientsFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    viewClient(id) {
      this.$refs.addClient.open(id);
    },
    addNewClient() {
      this.$refs.addClient.open();
    },
    onClientAdded(clientData, isNewClient) {
      if (isNewClient) {
        this.addAndEmit(clientData);
      }
    },
    getDataDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);
      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getData();
      }, 500);
    },
    refreshAutocomplete() {
      if (this.value && (!this.obj || this.value != this.obj.id)) {
        const index = this.clients.findIndex((elm) => elm.id == this.value);
        if (index == -1) {
          this.fetchObj();
        }
      } else {
        if (
          this.obj &&
          (this.clients.findIndex((elm) => elm.id == this.obj.id) == -1 ||
            this.value != this.obj.id)
        ) {
          this.addAndEmit(this.obj);
        }
      }
    },
    addAndEmit(item) {
      this.addUniqueArr(this.clients, [item], "id", true);
      this.emitClient(item.id);
    },
    loadMore(undefine, _, isIntersecting) {
      if (isIntersecting && this.total > this.clients.length && !this.isClientsLoading) {
        // this line to loadMore if there is no search
        if (!this.options.search) this.options.page++;
        // this line to clear search only if there is item selected
        if (this.value) this.options.search = "";
      }
    },
    fetchObj() {
      clientsAPI.getClientById(this.value).then((res) => {
        this.addAndEmit(res.data);
      });
    },
  },
  created() {
    this.getDataDebounced();
  },
  watch: {
    options: {
      handler() {
        this.getDataDebounced();
      },
      deep: true,
    },
    obj() {
      this.refreshAutocomplete();
    },
    value() {
      this.refreshAutocomplete();
    },
  },
  components: {
    EditClient,
  },
};
</script>
