<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : 1200"
    :allow-resize="nested ? false : false"
    :visible.sync="slideouts.stats.active"
    :append-to="nested ? '' : '#app'"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small>
        <i class="fad fa-chart-pie mr-2"></i>
        Naming Template Stats{{ template && template.name ? `: '${template.name}'` : null }}
      </h3>
      <v-btn @click="slideouts.stats.active = false" icon><i class="far fa-times"></i></v-btn>
    </template>
    <template>
      <div>
        <div color="white" style="position: sticky; top: 0; z-index: 1">
          <v-tabs v-model="slideouts.tab" color="error">
            <v-tab :key="0">
              Drawing Documents
              <v-chip
                :color="slideouts.tab == 0 ? 'error' : 'grey lighten-4'"
                class="ml-2 font-weight-bold"
                small
                label
              >
                {{ drawingDocumentsCount }}
              </v-chip>
            </v-tab>
            <v-tab :key="1">
              Clients
              <v-chip
                :color="slideouts.tab == 1 ? 'error' : 'grey lighten-4'"
                class="ml-2 font-weight-bold"
                small
                label
              >
                {{ clientsCount }}
              </v-chip>
            </v-tab>
            <v-tab :key="2">
              Projects
              <v-chip
                :color="slideouts.tab == 2 ? 'error' : 'grey lighten-4'"
                class="ml-2 font-weight-bold"
                small
                label
              >
                {{ projectsCount }}
              </v-chip>
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
        </div>
        <v-tabs-items
          v-model="slideouts.tab"
          class="template-stats-tabs"
          v-if="slideouts.stats.active"
        >
          <v-tab-item :key="0">
            <naming-template-has-drawing-documents
              :template="template"
              :active="slideouts.stats.active"
              :storageKey="storageKey"
            ></naming-template-has-drawing-documents>
          </v-tab-item>
          <v-tab-item :key="1">
            <naming-template-has-clients
              :template="template"
              :active="slideouts.stats.active"
              :storageKey="storageKey"
            ></naming-template-has-clients>
          </v-tab-item>
          <v-tab-item :key="2">
            <naming-template-has-projects
              :template="template"
              :active="slideouts.stats.active"
              :storageKey="storageKey"
            ></naming-template-has-projects>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="slideouts.stats.active = false">
          <i class="fal fa-times mr-2"></i>
          Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import namingTemplateService from "../services/namingTemplates-service";
import NamingTemplateHasClients from "./NamingTemplateHasClients.vue";
import NamingTemplateHasDrawingDocuments from "./NamingTemplateHasDrawingDocuments.vue";
import NamingTemplateHasProjects from "./NamingTemplateHasProjects.vue";

export default {
  components: {
    NamingTemplateHasDrawingDocuments,
    NamingTemplateHasProjects,
    NamingTemplateHasClients,
  },
  name: "naming-template-stats",
  data() {
    return {
      storageKey: "NamingTemplateStats",
      template: null,
      drawingDocumentsCount: 0,
      clientsCount: 0,
      projectsCount: 0,
      slideouts: {
        tab: 0,
        stats: {
          active: false,
          isLoading: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTemplateStats() {
      if (this.template) {
        namingTemplateService.getTemplateState(this.template.id).then((resp) => {
          this.clientsCount = resp.data.linkedClientsCount;
          this.projectsCount = resp.data.linkedProjectsCount;
          this.drawingDocumentsCount = resp.data.linkedDrawingDocumentsCount;
        });
      }
    },
    open(template) {
      this.template = template;
      this.getTemplateStats();
      this.slideouts.stats.active = true;
      this.slideouts.tab = 0;
    },
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}
.template-stats-tabs.v-window.v-item-group.v-tabs-items {
  overflow: auto;
}
</style>
