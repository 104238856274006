<template>
  <v-container fluid class="px-6 pt-2" style="background: #eceff1">
    <v-row class="mt-2 mb-2" justify="center" align="center">
      <v-col>
        <h3>Drawing Documents that has this naming template linked to it!</h3>
      </v-col>
      <v-col lg="5" md="2" sm="6" class="d-flex justify-end">
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      dense
      class="elevation-2"
      :headers="headers"
      :items="entities"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      :options.sync="options"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>
      <template v-slot:[`header.jiraTicket`]="{ header }">
        <i :class="header.icon"></i>
        {{ header.text.toUpperCase() }}
      </template>
      <template v-slot:[`header.actions`]>
        <i class="fad fa-cog pl-2"></i>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <document-type :type="item.type"></document-type>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div class="document-name">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:[`item.number`]="{ item }">
        <div class="document-sheet-number font-weight-bold mono-font">{{ item.number }}</div>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <status-item small :statusList="statusList" :status="item.state"></status-item>
      </template>
      <template v-slot:[`item.assignee`]="{ item }">
        <user-avatar :user="item.assignee" icon></user-avatar>
      </template>
      <template v-slot:[`item.views`]="{ item }">
        <count-tag icon="fa-layer-group" :count="item.views ? item.views.length : 0"></count-tag>
      </template>
      <template v-slot:[`item.jiraTicket`]="{ item }">
        <div class="document-jira-ticket">
          <a
            v-if="item.jiraTicket != null && item.jiraTicket != ''"
            target="_blank"
            class="jira-external-link"
            @click.stop
            :href="'https://dangeloconsultants.atlassian.net/browse/' + item.jiraTicket"
          >
            <span class="icon-wrpr mr-1">
              <i class="fab fa-jira org-icon"></i>
              <i class="far fa-external-link alt-icon"></i>
            </span>
            <span>{{ item.jiraTicket }}</span>
          </a>
          <span v-else class="text--disabled">
            <i class="fad fa-unlink"></i>
          </span>
        </div>
      </template>
      <template v-slot:[`item.createDate`]="{ item }">
        <dater :date="item.createDate" date-only></dater>
      </template>
      <template v-slot:[`item.updateDate`]="{ item }">
        <dater :date="item.updateDate" date-only></dater>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon elevation="0" @click.stop="rowClicked(item)">
          <i class="far fa-eye"></i>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <edit-drawing-document
      ref="viewDrawingDocument"
      :projectId="projectIdToOpen"
      nested
    ></edit-drawing-document>
  </v-container>
</template>

<script>
import Enums from "../../../plugins/enums";
import DocumentType from "./DocumentType.vue";
import namingTemplateService from "../services/namingTemplates-service";
import drawingDocumentHeader from "../config/tables/drawingDocument.header";
import EditDrawingDocument from "./EditDrawingDocument.vue";

export default {
  components: { EditDrawingDocument, DocumentType },
  name: "template-has-drawing-documents",
  props: {
    template: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    storageKey: {
      type: String,
    },
  },
  data() {
    return {
      statusList: Enums.DOCUMENT_STATE,
      entities: [],
      projectIdToOpen: null,
      total: 0,
      clientsTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
      loadingStates: {
        table: false,
      },
      headers: drawingDocumentHeader,
    };
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  methods: {
    getTemplateItems() {
      this.$backToTop(
        0,
        document.querySelector(".template-stats-tabs.v-window.v-item-group.v-tabs-items")
      );
      this.loadingStates.table = true;
      if (this.template) {
        namingTemplateService
          .getTemplateItems(this.template.id, this.$clean(this.options, true), "DrawingDocuments")
          .then((resp) => {
            this.entities = resp.data.items;
            this.total = resp.data.total;
            this.loadingStates.table = false;
          })
          .catch(() => {
            this.loadingStates.table = false;
          });
      }
    },
    getProjectDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getTemplateItems();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);

      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getTemplateItems();
      }, 400);
    },
    rowClicked(item) {
      this.projectIdToOpen = item.projectId;
      this.$refs.viewDrawingDocument.open(item.id, false);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectDebounced();
      },
      deep: true,
    },
    active(val) {
      if (val) {
        this.options.page = 1;
        this.getProjectDebounced();
      } else {
        this.entities = [];
        this.total = 0;
      }
    },
  },
};
</script>
